/**
 * File library.js
 * 
 * Custom scripts.
 */

(function($) {

	console.log($);
	

	// Registering GSAP plugin

	gsap.registerPlugin(ScrollTrigger);

	// Global Variables

	var headerHeight = $('#masthead').outerHeight()
	
	// All-click objects

	function allClick() {
		var clickbox = $('.allclick');
		clickbox.each(function() {
			var $this = $(this);
			$this.css('cursor', 'pointer');
			$this.find('a').on('click', function(e) {
				e.preventDefault();
			});
			
			if ($this.find('a').attr('target') == '_blank') {
				$this.on('click', function(e) {
					window.open($('a', this).attr('href'));
				});
			} else {
				$this.on('click', function(e) {
					window.location.href = $('a', this).attr('href');
				});
			}
		});
	}

	// Smooth scrolling

	// function smoothScroll() {
	// 	$("a[href*='#']").not("[href='#'],[data-toggle]").on('click', function(e) {
			
	// 		if (this.hash !== "") {
	// 			e.preventDefault();

				

	// 			var hash = this.hash;

	// 			console.log($(hash).offset() - headerHeight);

	// 			$('html, body').animate({
	// 				scrollTop: $(hash).offset().top - headerHeight
	// 			}, 100);
	// 		}
	// 	});
	// }
	

	$( document ).ready(function() {

		allClick();
		// smoothScroll();

		// Adjust margin for page without header images
		
		if ( $('body').hasClass('no-header-images') ) {
			$('#content').css( 'padding-top', headerHeight );
		}

		$('#pre-content').css( 'padding-top', headerHeight );

		// Intro Animations (not home)

		$('body:not(.home) #page').addClass( 'init' );

	});



	// Code to run when the entire page is ready

	$( window ).on( 'load', function() {



// Codice per gestire l'apertura/chiusura del menu al clic del pulsante
$(document).ready(function() {
    // Assicurati che il logo invertito sia nascosto e quello normale visibile all'inizio
    $('.site-logo.inverted').css({'opacity': '0', 'visibility': 'hidden'});
	$('.site-logo.inverted img').css({'height': '0'}); // Nascondi il logo invertito
    $('.site-logo:not(.inverted)').css({'opacity': '1', 'visibility': 'visible'});
	$('.site-logo:not(.inverted) img').css({'height': 'auto'}); 
});
		
		if ( $(window).width() > 1023 ) {

			// Intro animation (Home)
			if ( $('body').hasClass('home') ) {
				var intro = gsap.timeline({
					paused: true,
					onComplete: function() {
						$('#intro-logo').css( 'opacity', 0 );
						$('#masthead').css( 'opacity', 1 );
					}
				});
			
				intro.to(
					'.menu-button',
					{
						delay: 0,
						duration: 0.1,
						opacity: 1
					}
				).to(
					'#main-cta',
					{
						duration: 0.1,
						opacity: 1
					},
					'<'
				);

				intro.play();
			}
		}

		  
		if ( $(window).width() > 767 ) {
			// Additional image
			var entry_image = $('.entry-image');

			if ( entry_image.length > 0 ) {
				gsap.from(
					entry_image,
					{
						scrollTrigger: {
							trigger: entry_image,
							start: 'top bottom',
							toggleClass: 'on',
							toggleActions: 'restart pause resume reverse'
						}
					}
				);
			}

			// Images reveal
			var containers = gsap.utils.toArray('.reveal');

			if ( containers.length > 0 ) {
				containers.forEach((container) => {
					var image = container.querySelector('img'),
						tl = gsap.timeline({
							scrollTrigger: {
								trigger: container,
								start: 'top bottom'
							}
						});
					
					tl.set(
						container,
						{
							autoAlpha: 1
						}
					);

					if ( container.classList.contains('right-side') ) {
						tl.from(
							container,
							1,
							{
								xPercent: 100,
								ease: 'Power2.easeOut'
							}
						);

						tl.from(
							image,
							1,
							{
								xPercent: -100,
								scale: 1.3,
								delay: -1,
								ease: 'Power2.easeOut'
							}
						);
					} else {
						tl.from(
							container,
							1,
							{
								xPercent: -100,
								ease: 'Power2.easeOut'
							}
						);

						tl.from(
							image,
							1,
							{
								xPercent: 100,
								scale: 1.3,
								delay: -1,
								ease: 'Power2.easeOut'
							}
						);
					}
				});
			}
		}

		
		$(document).ready(function() {

			gsap.registerPlugin(ScrollTrigger);

			if ($('.section_rooms_container').length) {
				$('.room_list button').on('click', function(){
					let id = $(this).attr('data-room')
					
					if (id=="all") {
						$('.section_rooms').addClass('active')
					} else {
						$('.section_rooms').removeClass('active')
						$(`#room_${id}`).addClass('active')
					}

					ScrollTrigger.refresh()
				})
			}

			document.querySelectorAll('.section_offers').forEach((section) => {
			    let img = section.querySelector('a.item img');
			
			    gsap.fromTo(img, 
			        {
			            clipPath: 'circle(95% at center)' // Raggio iniziale al 50% per vedere l'intera immagine
			        }, 
			        {
			            clipPath: 'circle(20% at center)', // Raggio finale al 20%
			            ease: "none",
			            scrollTrigger: {
			                trigger: section,
			                start: "top top+=60%", // Inizia l'animazione a 45% dello scroll della sezione
			                end: "bottom bottom",
			                scrub: true
			            }
			        }
			    );
			});
			gsap.registerPlugin(ScrollTrigger);

			$('.cta-button').hover(
				function() {
					// Quando si passa il mouse sopra
					$(this).closest('.col-9').find('h2')
						   .css({
							   'transform': 'scale(1.1)',
							   'transition': 'all 0.1s cubic-bezier(0.56, 0.29, 0.14, 1)'
						   });
				},
				function() {
					// Quando il mouse lascia l'elemento
					$(this).closest('.col-9').find('h2')
						   .css({
							   'transform': 'scale(1)',
							   'transition': 'all 0.1s cubic-bezier(0.56, 0.29, 0.14, 1)'
						   });
				},

				function() {
					// Quando si passa il mouse sopra
					$(this).closest('.col-9').find('p')
						   .css({
							   'color': '$color__black',
						   });
				},
				function() {
					// Quando il mouse lascia l'elemento
					$(this).closest('.col-9').find('p')
						   .css({
								'color': '$color__body_text',
						   });
				}
			);
		
			$('.col-9').hover(
				function() {
					var index = $(this).data('index');
					$('#line .offer-circle[data-index="' + index + '"]').css({
						opacity: 1,
						transform: 'scale(1)'
					});
				},
				function() {
					var index = $(this).data('index');
					$('#line .offer-circle[data-index="' + index + '"]').css({
						opacity: 0,
						transform: 'scale(0)'
					});
				}
			);
		
			$('#fullpage').fullpage({
				licenseKey: '2CE2998E-DCDA4B38-A881829B-F227096B',
				autoScrolling: false,
				setAllowScrolling: true,
				recordHistory: false,
				menu: '#menu',
				css3: true,
				scrollingSpeed: 1000,
				fitToSection: false,
		
				afterLoad: function(origin, destination, direction) {
					var sectionIndex = destination.index;
		
		
	            if (sectionIndex === 1) {
	                // Animate the text with a slide-up
	                gsap.to('.section:nth-child(2) .col_to_action a h1', {
	                    opacity: 1,
	                    y: 0,
	                    duration: 1
	                });

       				 // Controlla se la larghezza dello schermo è inferiore a 1024px
					animateVideos();
	            } else if (sectionIndex === 2) {
	                // Delay the opening of the offcanvas menu

						openClose_offcanvas();
						openClose_offcanvas_forSmallScreens();	

	            }


	            // Custom logic for other sections...
	            // Implement other animations and actions for different sections as needed

	            // Play videos in the current section
	            playVideosInSection(sectionIndex);
	        },

	        onLeave: function(origin, destination, direction) {
	            // Stop all videos when leaving a section
	            stopAllVideos();
				if (origin.index === 2 && direction === 'up') {

	        }
			if(destination.isLast && direction === 'down') {
				// L'utente sta lasciando l'ultima sezione di FullPage.js
				if(window.location.hash) {
					history.pushState("", document.title, window.location.pathname + window.location.search);
				}
			}
		}
	});

	function animateVideos() {
		// Seleziona tutti i video nella sezione specifica
		const videoContainers = document.querySelectorAll('.section:nth-child(2) .col_to_action a');
	
		videoContainers.forEach((container, index) => {
			const video = container.querySelector('video');
			const title = container.querySelector('.second'); // Seleziona il titolo relativo al video
			let directionVideo = video.classList.contains('right_cta') ? '100%' : '-100%'; // Determina la direzione in base alla classe
			let directionTitle = title.classList.contains('right_cta') ? '-140%' : '140%'; // Opposto di video se vuoi che entrino da lati opposti
	
			// Calcola le dimensioni e la posizione dei video in base alla larghezza dello schermo
			const videoWidth = window.innerWidth < 1024 ? '80vw' : '35vw';
			const videoHeight = window.innerWidth < 1024 ?'20vh' : '27vh';
	
			// Crea l'animazione GSAP per video e titolo
			gsap.timeline({
				scrollTrigger: {
					trigger: '.section:nth-child(2)',
					start: "top bottom", // Inizia l'animazione quando il trigger arriva al centro del viewport
					end: "bottom center+=100px", // Fine dell'animazione quando il fondo del trigger è al centro
					toggleActions: "play reverse play reverse", // Gioca in avanti all'entrata e al contrario all'uscita
					reverse: true, // Assicurati che l'animazione si inverta quando si scorre all'indietro
				}
			})
			.fromTo(video, {
				marginBottom: '20px', // Aggiungi un margine iniziale per l'effetto di partenza
				opacity: 0, // Inizia con un'opacità di 0
				filter: 'blur(30px)',
				borderRadius: '130px',
				opacity: 1,
				x: directionVideo, // Sposta il video fuori dallo schermo
			}, {
				marginBottom: '0px',
				width: videoWidth,
				height: videoHeight,
				filter: 'blur(0px)',
				duration: 0.2,
				opacity: 1,
				position: 'sticky',
				x: '0%', // Sposta il video nella posizione finale
			})
			.fromTo(title, {
				opacity: 0, // Assumendo che vuoi anche animare l'opacità del titolo
				x: directionTitle, // Sposta il titolo fuori dallo schermo
				filter: 'blur(30px)',
			}, {
				filter: 'blur(0px)',
				x: '0%', // Sposta il titolo nella posizione finale
				duration: 0.3,
				opacity: 1,
			}, "<"); // Questo '<' indica che l'animazione del titolo inizia nello stesso momento di quella del video
		});
	}
	

	function openClose_offcanvas() {
		// Animazione per aprire l'offcanvas solo quando si scrolla nella "section 3"

// Assicurati che gsap e ScrollTrigger siano registrati con gsap.registerPlugin(ScrollTrigger)

// Imposta l'animazione iniziale per spostare .offcanvas_header
gsap.fromTo('.offcanvas_header', {
    x: '100%', // Posizione iniziale fuori dal viewport
	opacity: 0,
}, {
    scrollTrigger: {
        trigger: ".offcanvas_header",
        start: "top bottom",
        end: "top top+=180px",
        scrub: true,
    },
    x: '55%', // Posizione finale
    y: '-5%',
    ease: "power1.in",
	opacity: 1,
    onComplete: () => {
        // Cambia l'opacità di #scroll_tag quando l'animazione è completa
        gsap.to('#scroll_tag', {
            opacity: 1,
            duration: 1.2 // Controlla la velocità della transizione di opacità
        });
    }	


});

	gsap.fromTo('#content_offcanvas', {
		x: '-45%', // Inizia da fuori dallo schermo (destra)
		position: 'unset',
		top: 'unset',
		left: 'unset',
		opacity: 0,

	}, {
		scrollTrigger: {
			trigger: ".offcanvas_header",
			start: "top center",
			end: "top top+=180px",
			scrub: true,
		},
		x: '0%', // Finisce spostato verso sinistra
		opacity: 1,
		ease: "none",
		position: 'sticky',
		top: '20px',
		left: '0',
	});

	gsap.utils.toArray('.col-12.sticky_col').forEach((col) => {
		gsap.fromTo(col, {
			opacity: 0,
			position: 'unset',
			top: 'unset',
			left: 'unset'
		}, {
			scrollTrigger: {
				trigger: '.offcanvas_header', // Usa l'elemento corrente come trigger
				start: "top center",
				end: "top top+=240px",
				scrub: true
			},
			opacity: 1,
			ease: "none",
			position: 'sticky',
			top: '20px', // Assicurati che questo valore sia appropriato per il layout
			left: '0',
		});
	});
	
		// Animazione per spostare il contenuto verso sinistra, se necessario
		gsap.fromTo('#section_2', {
			x: '0%', // Inizia da fuori dallo schermo (destra)
			opacity: 1,
		}, {
			scrollTrigger: {
				trigger: ".offcanvas_header",
				start: "top bottom",
				end: "top top+=180px",
				scrub: true,
			},
			x: '-45%', // Finisce spostato verso sinistra
			ease: "none",
			opacity: 0,
		});
	
		gsap.fromTo('.header-video.hd-media', {
			x: '0%', // Inizia da fuori dallo schermo (destra)
			willChange: 'transform',
		}, {
			scrollTrigger: {
				trigger: ".offcanvas_header",
				start: "top bottom",
				end: "top top+=180px",
				scrub: true,
			},
			x: '-45%', // Finisce spostato verso sinistra
			ease: "none",
		});
	}

	function openClose_offcanvas_forSmallScreens() {
		// Rileva la larghezza della finestra
		var larghezzaFinestra = window.innerWidth;
	
		if (larghezzaFinestra < 1028) {
			// Animazione per l'header dell'offcanvas per schermi piccoli
			gsap.to('.offcanvas_header', { duration: 0.001, onComplete: () => {
				gsap.fromTo('.offcanvas_header', {
					x: '100%', // Posizione iniziale fuori schermo
				},{
					scrollTrigger: {
						trigger: ".offcanvas_header",
						start: "top center",
						end: "top top+=150px",
						scrub: true,
					},
					x: '0%', // Sposta verso la posizione originale
					ease: "power2.in",
				});
			}});
	
			// Animazione per il contenuto dell'offcanvas per schermi piccoli
			gsap.fromTo('#content_offcanvas', {
				x: '-100%', // Inizia da fuori dallo schermo (sinistra)
			}, {
				scrollTrigger: {
					trigger: ".offcanvas_header",
					start: "top center",
					end: "top top+=150px",
					scrub: true,
				},
				x: '0%', // Finisce nella posizione originale
				opacity: 1,
				ease: "none",
			});
			// Animazione per spostare il contenuto verso sinistra, se necessario
			gsap.fromTo('#section_3', {
				x: '0%', // Inizia da fuori dallo schermo (destra)
			}, {
				scrollTrigger: {
					trigger: ".offcanvas_header",
					start: "top center",
					end: "top top+=150px",
					scrub: true,
				},
				x: '-100%', // Finisce spostato verso sinistra
				ease: "none",
			});
			// Animazione per lo spostamento del video header verso destra completamente
			gsap.fromTo('.header-video.hd-media', {
				x: '0%', // Inizia dalla sua posizione originale
			}, {
				scrollTrigger: {
					trigger: ".offcanvas_header",
					start: "top center",
					end: "top top+=150px",
					scrub: true,
				},
				x: '-100%', // Sposta completamente fuori verso destra
				ease: "none",
			});
		}
	}
	

	  
		const buoniMotivi = document.querySelectorAll('.buon_motivo');

		buoniMotivi.forEach((buonMotivo) => {

			if (window.innerWidth < 1024) {
				// Codice per dispositivi con larghezza inferiore a 1024px
		
				// Il primo testo scompare allo scroll
				gsap.to(buonMotivo.querySelector('.primo_testo'), {
					scrollTrigger: {
						trigger: buonMotivo,
						start: '10% top',
						end: '28% center',
						scrub: true,

					},
					duration: 0.2,
					opacity: 0,
					ease: 'none',
				});

				        // Animazione per il testo del secondo motivo
						gsap.to(buonMotivo.querySelector('#p_primo_testo'), {
							scrollTrigger: {
								trigger: buonMotivo,
								start: '10% top',
								end: '22% center',
								scrub: true,
							},
							duration: 0.1,
							opacity: '0',
							ease: 'none',
						});
										// Animazione per l'immagine

				gsap.fromTo(buonMotivo.querySelector('.img_container'), {
				}, {
					scrollTrigger: {
						trigger: buonMotivo,
						start: 'top top',
						end: '28% center',
						scrub: true,
					},
					ease: 'none',
				});

        		// Seconda Animazione: Sposta a sinistra dopo il completamento della prima
        		gsap.to(buonMotivo.querySelector('.img_container'), {
        		    scrollTrigger: {
        		        trigger: buonMotivo,
        		        start: '40% center',
        		        end: '50% center',
        		        scrub: true,
        		    },
        		    ease: 'none',
        		});
		
				// Secondo testo
				gsap.to(buonMotivo.querySelector('.secondo_testo'), {
				    scrollTrigger: {
				        trigger: buonMotivo,
				        start: '23% center',
				        end: '55% center',
				        scrub: false,
						toggleActions: 'play reverse play reverse',
				        onLeave: () => {
				            // Inizia l'animazione del terzo testo quando il secondo testo finisce
				            const terzoTesto = buonMotivo.querySelector('.terzo_testo_container.small');
				            terzoTesto.style.opacity = 1;
				        },
				        onEnterBack: () => {
				            // Nascondi il terzo testo quando si torna indietro
				            const terzoTesto = buonMotivo.querySelector('.terzo_testo_container.small');
				            terzoTesto.style.opacity = 0;
				        }
				    },
				    opacity: 2,
				    ease: 'none',
				});

				// Terzo testo (senza scrollTrigger)
				const terzoTesto = buonMotivo.querySelector('.terzo_testo_container.small');
				gsap.to(terzoTesto, {
				    opacity: 0,
				    ease: 'none',
				    paused: true, // Inizia in pausa e viene controllato dal secondo testo
				});


				document.addEventListener('scroll', () => {
					document.querySelectorAll('.buon_motivo').forEach((motivo, index) => {
						const windowTop = window.scrollY;
						const windowBottom = windowTop + window.innerHeight;
						// Calcoliamo il punto di metà dello schermo rispetto all'alto della pagina
						const windowMiddle = windowTop + (window.innerHeight * 2.5);
				
						const motivoTop = motivo.getBoundingClientRect().top + windowTop;
						const motivoBottom = motivoTop + motivo.offsetHeight;
				
						// Verifica se la metà del .buon_motivo è visibile nella viewport
						const isMotivoMiddleVisible = windowMiddle >= motivoTop && windowMiddle <= motivoBottom;
				
						if (isMotivoMiddleVisible) {
							motivo.style.backgroundColor = 'white'; // Tiffany
						} else {
							motivo.style.backgroundColor = '#35c1b8'; // Bianco
						}
					});
				});
				
				
				
				
		
			} else {
				// Animazione per il testo del primo motivo
				gsap.to(buonMotivo.querySelector('h6.primo_testo'), {
					scrollTrigger: {
					  trigger: buonMotivo,
					  start: '10% top',
					  end: '22% center',
					  scrub: true,
					  toggleActions: 'play reverse play reverse',
					},
					duration: 0.2, // Durata dell'animazione
					opacity: '0', // Rendi il testo trasparente
					ease: 'none',
					scale: 1.5,
					
		
				  });
				  
				// Animazione per il testo del secondo motivo
				gsap.to(buonMotivo.querySelector('p#p_primo_testo'), {
					scrollTrigger: {
						trigger: buonMotivo,
						start: '9% top',
						end: '20% center',
						scrub: true,
						toggleActions: 'play reverse play reverse',
					},
					duration: 0.1,
					opacity: '0',
					ease: 'none',
					
		
				});
				// Animazione per l'immagine

				gsap.fromTo(buonMotivo.querySelector('.img_container'), {
					yPercent: 0 // Parte da una posizione leggermente più in alto
				}, {
					scrollTrigger: {
						trigger: buonMotivo,
						start: 'top top',
						end: '28% center',
						scrub: true,
						toggleActions: 'play reverse play reverse',
					},
					yPercent: 2,
					xPercent: 18, // Si sposta verso la posizione centrale (y 0)
					ease: 'none',
				});

        		// Seconda Animazione: Sposta a sinistra dopo il completamento della prima
        		gsap.to(buonMotivo.querySelector('.img_container'), {
        		    scrollTrigger: {
        		        trigger: buonMotivo,
        		        start: '40% center',
        		        end: '50% center',
        		        scrub: true,
						toggleActions: 'play reverse play reverse',
        		    },
        		    xPercent: -12,
        		    ease: 'none',
        		});
				document.addEventListener('scroll', () => {
					document.querySelectorAll('.buon_motivo').forEach((motivo, index) => {
						const windowTop = window.scrollY;
						const windowBottom = windowTop + window.innerHeight;
						// Calcoliamo il punto di metà dello schermo rispetto all'alto della pagina
						const windowMiddle = windowTop + (window.innerHeight * 2.5);
				
						const motivoTop = motivo.getBoundingClientRect().top + windowTop;
						const motivoBottom = motivoTop + motivo.offsetHeight;
				
						// Verifica se la metà del .buon_motivo è visibile nella viewport
						const isMotivoMiddleVisible = windowMiddle >= motivoTop && windowMiddle <= motivoBottom;
				
						if (isMotivoMiddleVisible) {
							motivo.style.backgroundColor = 'white'; // Tiffany
						} else {
							motivo.style.backgroundColor = '#35c1b8'; // Bianco
						}
					});
				});
					
				
				}

				// Far apparire il secondo testo
				gsap.to(buonMotivo.querySelector('.secondo_testo'), {
				    scrollTrigger: {
				        trigger: buonMotivo,
				        start: '23% center',
				        end: '55% center',
						toggleActions: 'play reverse play reverse',
				        scrub: false
				    },
				    opacity: 1, // Imposta l'opacità a 1 per rendere il testo visibile
				    ease: 'none'
				});

				// Far scomparire il secondo testo
				gsap.to(buonMotivo.querySelector('.secondo_testo'), {
				    scrollTrigger: {
				        trigger: buonMotivo,
				        start: '55% center', // Questo dovrebbe iniziare dove finisce l'animazione precedente
				        end: '58% center',
						toggleActions: 'play reverse play reverse',
				        scrub: false
				    },
				    opacity: 0, // Imposta l'opacità a 0 per far scomparire il testo
				    ease: 'none'
				});


		document.querySelectorAll('.buon_motivo').forEach(buonMotivo => {
	    const wrapperMotivi = buonMotivo.closest('.wrapper_motivi');
	    const bars = buonMotivo.querySelectorAll('.level_bar');
	    const totalBars = bars.length;
	    const terzoTesto = buonMotivo.querySelector('.terzo_testo');
		
	    bars.forEach((bar, index) => {
	        let reversedIndex = totalBars - 1 - index;
		
	        let startScroll, endScroll;
	        // Definizione startScroll e endScroll come nel tuo codice
			if (window.innerWidth < 1024) {
				// Per dispositivi con larghezza inferiore a 1024px
				startScroll = (reversedIndex / totalBars) * 100 + 40 + '% top'; // Ritarda l'inizio dell'animazione
				endScroll = ((reversedIndex + 1) / totalBars) * 30 + '% top'; // Fine dell'animazione
			} else {
				// Per dispositivi con larghezza superiore a 1024px
				startScroll = (reversedIndex / totalBars) * 100 + 40 + '% top';
				endScroll = ((reversedIndex + 1) / totalBars) * 30 + '% top';
			}
	        // Animazione per ciascuna barra
	        gsap.to(bar, {
	            scrollTrigger: {
	                trigger: wrapperMotivi,
	                start: startScroll,
	                end: endScroll,
					
	                scrub: true,
					reversed: true,
					onEnter: () => bar.style.opacity = 1,
					onEnterBack: () => bar.style.opacity = 1,
					onLeaveBack: () => bar.style.opacity = 0,
	            },
	            backgroundColor: 'white',
	            ease: 'none',
	            reversed: true
	        });
	        gsap.to(terzoTesto, {
	            scrollTrigger: {
	                trigger: wrapperMotivi,
	                start: startScroll,
	                end: endScroll,
	                scrub: true,
					
					
					onEnter: () => terzoTesto.style.opacity = 1,
					onEnterBack: () => terzoTesto.style.opacity = 1,
					onLeaveBack: () => terzoTesto.style.opacity = 0,				

	            },
	            
	            ease: 'none'
	        });
	    });
	  });
	});

	
	// Animazione per image_top_left che scorre verso il basso allo scroll
	gsap.to('.image_top_left', {
	    scrollTrigger: {
	        trigger: '#extra1', // Utilizza #extra1 come trigger
	        start: 'top bottom', // Inizia quando il top di #extra1 entra nel viewport
	        end: 'bottom 50%', // Termina quando #extra1 è sceso del 50% nel viewport
	        scrub: true,
	    },
	    y: '-60%',
	    ease: 'none',
	});

	// Animazione per image_bottom_right che scorre verso l'alto allo scroll
	gsap.to('.image_bottom_right', {
	    scrollTrigger: {
	        trigger: '#extra1', // Utilizza #extra1 come trigger
	        start: 'top bottom', // Inizia quando il top di #extra1 entra nel viewport
	        end: 'bottom 50%', // Termina quando #extra1 è sceso del 50% nel viewport
	        scrub: true,
	    },
	    y: '50%',
	    ease: 'none',
	});

	gsap.to('.down', {
		scrollTrigger: {
			trigger: '.line-break-right',
			start: 'top bottom',
			end: 'bottom 50%',
			scrub: true,
		},
		width: '55%',
		opacity: 1,
		ease: 'power2.inOut',
	})

			// Animazione per image_top_left che scorre verso il basso allo scroll
	gsap.to('.image_up', {
	    scrollTrigger: {
	        trigger: '#experience', // Utilizza #experience come trigger
	        start: 'top bottom', // Inizia quando il top di #experience entra nel viewport
	        end: 'bottom top', // Termina quando #experience è sceso del 50% nel viewport
	        scrub: true,
	    },
	    y: '-80%',
	    ease: 'none',
	});

	// Animazione per image_bottom_right che scorre verso l'alto allo scroll
	gsap.to('.image_down', {
	    scrollTrigger: {
	        trigger: '#experience', // Utilizza #experience come trigger
	        start: 'top bottom', // Inizia quando il top di #experience entra nel viewport
	        end: 'bottom top', // Termina quando #experience è sceso del 50% nel viewport
	        scrub: true,
	    },
	    y: '70%',
	    ease: 'none',
	});


	});
	
				// Funzioni per gestire i video
				function stopAllVideos() {
					$('video .stop_video').each(function() {
						this.pause();
					});
				}
				
				function playVideosInSection(sectionIndex) {
					$('.section').eq(sectionIndex).find('video').each(function() {
						this.play();
					});
				}

		/* GSAP Animations - End */

		$('#site-navigation .menu-item-has-children > a').click(function(e) {
			e.preventDefault();

		});
		
		
		
});
window.addEventListener('DOMContentLoaded', function() {
    // Determina se il dispositivo è considerato piccolo
    let isSmall = window.innerWidth < 768;

    // Gestione del disegno della linea per #path
    let path = document.querySelector('#path');
    if (path) {
        let pathLength = path.getTotalLength();
        path.style.strokeDasharray = pathLength + ' ' + pathLength;
        path.style.strokeDashoffset = pathLength;
        manageScrollAnimation(path, pathLength, isSmall);
    } else {
        console.log("Elemento #path non trovato.");
    }

    // Gestione del disegno della linea per #path_small
    let path_small = document.querySelector('#path_small');
    if (path_small) {
        let path_small_Length = path_small.getTotalLength();
        path_small.style.strokeDasharray = path_small_Length + ' ' + path_small_Length;
        // Per #path_small l'offset iniziale è impostato per farlo disegnare al contrario
        path_small.style.strokeDashoffset = -path_small_Length;
        manageScrollAnimation(path_small, path_small_Length, isSmall);
    } else {
        console.log("Elemento #path_small non trovato.");
    }
});

function manageScrollAnimation(path, pathLength, isSmall) {
    let animationCompleted = false;
    window.addEventListener('scroll', function() {
            let scrollPercentage = (document.documentElement.scrollTop + document.body.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight);
            scrollPercentage = isSmall ? scrollPercentage * 1.2 : scrollPercentage / 1.6;
            let drawLength = pathLength * scrollPercentage;
            path.style.strokeDashoffset = isSmall ? -pathLength + drawLength : pathLength - drawLength;
    });
}

window.addEventListener('DOMContentLoaded', function() {
	gsap.registerPlugin(ScrollTrigger);
	if (window.innerWidth >= 768) {
		function creaAnimazioneScrollTrigger(selectorAnimazione, triggers, proprieta) {
			// Verifica che esistano elementi corrispondenti al selettore
			if (document.querySelectorAll(selectorAnimazione).length > 0) {
				triggers.forEach(trigger => {
					// Assicurati che il trigger esista
					if (document.querySelector(trigger)) {
						gsap.to(selectorAnimazione, {
							scrollTrigger: {
								trigger: trigger, // Usa il trigger passato alla funzione
								start: proprieta.start,
								end: proprieta.end,
								scrub: true,
								reverse: true,
							},
							y: proprieta.y,
							x: proprieta.x,
							ease: 'none',
						});
					} else {
						console.warn(`Trigger ${trigger} non trovato.`);
					}
				});
			} else {
				console.warn(`Elementi ${selectorAnimazione} non trovati.`);
			}
		}
		
	
	// Definizione delle proprietà per l'animazione di ciascun gruppo di immagini
	let proprietaAnimazioneUp = { start: 'top top', end: 'bottom top', y: '-40%' };
	let proprietaAnimazioneDown = { start: 'top top', end: 'bottom top', y: '40%' };
	let proprietaAnimazioneLeft = { start: 'top top', end: 'bottom top', x: '-50%' };
	
	// Creazione delle animazioni per ciascun gruppo di immagini
			// Applica l'animazione agli elementi specifici all'interno della row corrente
			creaAnimazioneScrollTrigger(`#row-1 .image_up_gallery`, ['.col-6.seconda', '.col-4.terza', '.col-4.quinta'], proprietaAnimazioneUp);
			creaAnimazioneScrollTrigger(`#row-2 .image_down_gallery`,  ['.col-6.prima', '.col-4.quarta'], proprietaAnimazioneDown);
			creaAnimazioneScrollTrigger(`#row-3 .image_left_gallery`, ['.col-12'], proprietaAnimazioneLeft);
			creaAnimazioneScrollTrigger(`#row-4 .image_up_gallery`, ['.col-6.seconda', '.col-4.terza', '.col-4.quinta'], proprietaAnimazioneUp);
			creaAnimazioneScrollTrigger(`#row-5 .image_down_gallery`, ['.col-6.prima', '.col-4.quarta'], proprietaAnimazioneDown);
			creaAnimazioneScrollTrigger(`#row-6 .image_left_gallery`, ['.col-12'], proprietaAnimazioneLeft);
			creaAnimazioneScrollTrigger(`#row-7 .image_up_gallery`, ['.col-6.seconda', '.col-4.terza', '.col-4.quinta'], proprietaAnimazioneUp);
			creaAnimazioneScrollTrigger(`#row-8 .image_down_gallery`, ['.col-6.prima', '.col-4.quarta'], proprietaAnimazioneDown);
			creaAnimazioneScrollTrigger(`#row-9 .image_left_gallery`, ['.col-12'], proprietaAnimazioneLeft);
			creaAnimazioneScrollTrigger(`#row-10 .image_up_gallery`, ['.col-6.seconda', '.col-4.terza', '.col-4.quinta'], proprietaAnimazioneUp);
			creaAnimazioneScrollTrigger(`#row-11 .image_down_gallery`, ['.col-6.prima', '.col-4.quarta'], proprietaAnimazioneDown);
			creaAnimazioneScrollTrigger(`#row-12 .image_left_gallery`, ['.col-12'], proprietaAnimazioneLeft);
			
			
	}
});
	// Code to run when the browser window has been resized

	var timer_id;

	$( window ).on( 'resize', function() {

		clearTimeout(timer_id);
		timer_id = setTimeout(function() {

			if ( $(window).width() >= 1024 ) {
				// Adjust margin for page without header images
		
				if ( $('body').hasClass('no-header-images') ) {
					$('#content').css( 'padding-top', headerHeight );
				}

				$('#pre-content').css( 'padding-top', headerHeight );
			}
			
		}, 300);

	});

})(jQuery);

/* Swiper - Begin */

// Rooms slideshow

document.addEventListener("DOMContentLoaded", function() {
    // Inizializza Swiper per Desktop
    const roomsSlider = new Swiper('.inner-desktop .rooms-slideshow', {
        slidesPerView: 1,
        speed: 1000,
        allowTouchMove: false,
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },
        navigation: {
            nextEl: '.inner-desktop .swiper-button-next',
            prevEl: '.inner-desktop .swiper-button-prev',
        },
    });

    // Inizializza Swiper per Mobile
    const mobileRoomsSlider = new Swiper('.inner-mobile .rooms-slideshow', {
        slidesPerView: 1,
        spaceBetween: 10,
        allowTouchMove: true,
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },
        navigation: {
            nextEl: '.inner-mobile .swiper-button-next',
            prevEl: '.inner-mobile .swiper-button-prev',
        },
    });

    // Aggiorna Swiper per Mobile dopo che tutto il contenuto è stato caricato
    window.onload = function() {
        mobileRoomsSlider.update();
    };
});

const offersSlider = new Swiper('#offers .offers-slideshow', {
	slidesPerView: 1,
	spaceBetween: 30,
	speed: 1000,
	allowTouchMove: false,
	navigation: {
		nextEl: '#offers .swiper-button-next',
		prevEl: '#offers .swiper-button-prev',
	},
	breakpoints: {
		768: {
			slidesPerView: 2,
			spaceBetween: 90,
			speed: 1500,
		},
	},
});

// Reasons slideshow

const reasonsSlider = new Swiper('#reasons .reasons-slideshow', {
	slidesPerView: 1,
	speed: 1000,
	allowTouchMove: false,
	effect: 'fade',
	fadeEffect: {
		crossFade: true,
	},
	navigation: {
		nextEl: '#reasons .swiper-button-next',
		prevEl: '#reasons .swiper-button-prev',
	},
});

// Reviews slideshow
document.addEventListener('DOMContentLoaded', function() {
    const reviewsSlider = new Swiper('#reviews .reviews-slideshow', {
        slidesPerView: 1,
        speed: 1000,
        allowTouchMove: false,
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },
        navigation: {
            nextEl: '#reviews .swiper-button-next',
            prevEl: '#reviews .swiper-button-prev',
        },
        on: {
            init: function(swiper) {
                updateWrapperHeight(swiper);
            },
            slideChangeTransitionEnd: function(swiper) {
                updateWrapperHeight(swiper);
            }
        }
    });

    function updateWrapperHeight(swiper) {
        const activeSlide = swiper.slides[swiper.activeIndex];
        const slideHeight = activeSlide.offsetHeight;

        // Update the wrapper height
        swiper.wrapperEl.style.height = `${slideHeight}px`;
    }

    // Initial height update for the first slide
    const initialSlide = reviewsSlider.slides[reviewsSlider.activeIndex];
    if (initialSlide) {
        updateWrapperHeight(reviewsSlider);
    }
});


// Social slideshow

const socialSlider = new Swiper('#social .social-slideshow', {
	loop: true,
	centeredSlides: true,
	slidesPerView: 1.75,
	spaceBetween: 15,
	speed: 1000,
	allowTouchMove: false,
	autoplay: {
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	breakpoints: {
		768: {
			slidesPerView: 2.75,
			spaceBetween: 30,
			speed: 1000
		}
	},
});

document.addEventListener('DOMContentLoaded', function () {
	const interna = new Swiper("#template_interna .single_room-slideshow", {
		effect: "coverflow",
		grabCursor: true,
		centeredSlides: true,
		simulateTouch: true, // Assicurati che il trascinamento sia abilitato
		touchRatio: 1, // Regola questo valore se necessario per ottimizzare la sensibilità del trascinamento
		coverflowEffect: {
		  rotate: 0,
		  stretch: 0,
		  depth: 100,
		  modifier: 2.5
		},
		keyboard: {
		  enabled: true
		},
	
		spaceBetween: 30,
		loop: true,
		breakpoints: {
		  640: {
			slidesPerView: 2
		  },
		  1024: {
			slidesPerView: 3
		  }
		},
		navigation: {
			nextEl: ' #navigation_small .swiper-button-next',
			prevEl: ' #navigation_small .swiper-button-prev',
		},
	  });
	});

document.addEventListener('DOMContentLoaded', function () {
const room = new Swiper("#single_room .single_room-slideshow", {
	effect: "coverflow",
	grabCursor: true,
	centeredSlides: true,
	simulateTouch: true, // Assicurati che il trascinamento sia abilitato
    touchRatio: 1, // Regola questo valore se necessario per ottimizzare la sensibilità del trascinamento
	coverflowEffect: {
	  rotate: 0,
	  stretch: 0,
	  depth: 100,
	  modifier: 2.5
	},
	keyboard: {
	  enabled: true
	},

	spaceBetween: 30,
	loop: true,
	breakpoints: {
	  640: {
		slidesPerView: 2
	  },
	  1024: {
		slidesPerView: 3
	  }
	},
	navigation: {
		nextEl: '#room_swiper .swiper-button-next',
		prevEl: '#room_swiper .swiper-button-prev',
	},
  });
});

document.addEventListener('DOMContentLoaded', function () {
	var swiper = new Swiper("#single_offer .single_room-slideshow", {
		effect: "coverflow",
		grabCursor: true,
		centeredSlides: true,
		simulateTouch: true, // Assicurati che il trascinamento sia abilitato
		touchRatio: 1, // Regola questo valore se necessario per ottimizzare la sensibilità del trascinamento
		coverflowEffect: {
		  rotate: 0,
		  stretch: 0,
		  modifier: 2.5
		},
		keyboard: {
		  enabled: true
		},
	
		spaceBetween: 300,
		loop: true,
		breakpoints: {
		  640: {
			slidesPerView: 1
		  },
		  1024: {
			slidesPerView: 2
		  }
		},
		navigation: {
			nextEl: '#room_swiper .swiper-button-next',
			prevEl: '#room_swiper .swiper-button-prev',
		},
	  });
	});
/* Swiper - End */
jQuery(document).ready(function($) {
    var lastTop = 0;
    jQuery('.list li').each(function() {
        var $this = jQuery(this);
        if ($this.position().top > lastTop) {
            // Questo elemento è all'inizio di una nuova riga
            $this.find('.point').hide(); // Nasconde il punto per questo elemento
        }
        lastTop = $this.position().top;
    });
});
jQuery(document).ready(function($) {
    // Intercepita il click sui menu-item che hanno sottovoci
    $('.menu-item-has-children > a').click(function(e) {
        // Previene il comportamento di default dell'ancora
        e.preventDefault();
        
        // Toggle della visibilità della sottovoce
        $(this).next('.sub-menu').toggleClass('open');

        // Opzionale: Aggiungi qui ulteriore logica per animazioni o per modificare l'aspetto
    });
});


// Lightbox effects

const lightbox = GLightbox({
	loop: true
});



// document.addEventListener('DOMContentLoaded', (event) => {
//     let lastScrollTop = window.scrollY;
//     window.addEventListener('scroll', function() {
//         let st = window.scrollY;
//         if (st > lastScrollTop){
//             // Scroll verso il basso
//             if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
//                 // Sei in fondo alla pagina
// 				// Aggiungi una classe per nascondere
// 				document.getElementById('main-cta').classList.add('hidden');
//             }
//         } else {
//             // Scroll verso l'alto
// 			// Rimuovi la classe per mostrare e assicurare flex
// 			document.getElementById('main-cta').classList.remove('hidden');
//         }
//         lastScrollTop = st <= 0 ? 0 : st; // Per browser che non gestiscono bene i valori negativi di scroll
//     }, false);
// });







